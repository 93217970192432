import {
  map,
  indexOf,
  cloneDeep,
  assign,
  keys,
  includes,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import Event from './event';

export const ID = '6093d1bf-d572-486b-af56-96287ee0e865';

const Periods = {
  PRE_GAME: 'PRE_GAME',
  FIRST_PERIOD: 'FIRST_PERIOD',
  BEFORE_SECOND_PERIOD: 'BEFORE_SECOND_PERIOD',
  SECOND_PERIOD: 'SECOND_PERIOD',
  FIRST_HALF: 'FIRST_HALF',
  HALF_TIME: 'HALF_TIME',
  THIRD_PERIOD: 'THIRD_PERIOD',
  BEFORE_FOURTH_PERIOD: 'BEFORE_FOURTH_PERIOD',
  FOURTH_PERIOD: 'FOURTH_PERIOD',
  SECOND_HALF: 'SECOND_HALF',
  BEFORE_OVERTIME: 'BEFORE_OVERTIME',
  OVERTIME_1: 'OVERTIME_1',
  OVERTIME_2: 'OVERTIME_2',
  POST_GAME: 'POST_GAME',
  GAME_ABANDONED: 'GAME_ABANDONED',
};

const mustHavePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.FOURTH_PERIOD,
];

const mustHavePeriodsNcaa = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
];

const activePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.FOURTH_PERIOD,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
];

const PeriodLabels = {
  TOTAL: 'Full event',
  [Periods.FIRST_PERIOD]: 'Q1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'Q2',
  [Periods.HALF_TIME]: 'Half time',
  [Periods.THIRD_PERIOD]: 'Q3',
  [Periods.BEFORE_FOURTH_PERIOD]: 'Break',
  [Periods.FOURTH_PERIOD]: 'Q4',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const PeriodAbbreviations = {
  TOTAL: 'Full event',
  [Periods.FIRST_PERIOD]: 'Q1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'Q2',
  [Periods.HALF_TIME]: 'Half time',
  [Periods.THIRD_PERIOD]: 'Q3',
  [Periods.BEFORE_FOURTH_PERIOD]: 'Break',
  [Periods.FOURTH_PERIOD]: 'Q4',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const livePeriods = [
  Periods.FIRST_PERIOD,
  Periods.BEFORE_SECOND_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.FIRST_HALF,
  Periods.HALF_TIME,
  Periods.SECOND_HALF,
  Periods.THIRD_PERIOD,
  Periods.BEFORE_FOURTH_PERIOD,
  Periods.FOURTH_PERIOD,
  Periods.BEFORE_OVERTIME,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
];

const getLivePeriods = () => [...livePeriods];

const isPrematch = (event) => Event.isPrematch(event) || event.period === Periods.PRE_GAME;

const isLive = (event) => Event.isLive(event) && !includes([Periods.POST_GAME, Periods.GAME_ABANDONED, event.period]);

const getPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const getPeriodLabel = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  const label = PeriodLabels[period] || '';
  if (!includes(activePeriods, period)) return label;

  const time = getPeriodTime({
    period,
    periodLengthInMinutes,
    overtimeLengthInMinutes,
    secondsLeftInPeriod,
  });

  return `${label} · ${time}`;
};
const getPeriodValue = (period) => {
  if (period === 'TOTAL') return 0;
  return indexOf(keys(Periods), period) + 1;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: [
        Periods.FIRST_PERIOD,
        Periods.SECOND_PERIOD,
        Periods.THIRD_PERIOD,
        Periods.FOURTH_PERIOD,
      ],
    }),
    (period) => {
      const isVisible = !prematch && getPeriodValue(event.period) >= getPeriodValue(period);
      return {
        code: period,
        label: PeriodAbbreviations[period],
        homeValue: isVisible ? event.home.scorePerPeriod[period] || 0 : '',
        awayValue: isVisible ? event.away.scorePerPeriod[period] || 0 : '',
      };
    },
  );

  return [
    {
      code: 'TOTAL',
      label: PeriodAbbreviations.TOTAL,
      homeValue: prematch ? '-' : event.home.score,
      awayValue: prematch ? '-' : event.away.score,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      lastTeamToScore: payload.state.lastTeamToScore,
      teamInPossession: payload.state.teamInPossession,
      period: payload.state.period,
      secondsLeftInPeriod: payload.state.secondsLeftInPeriod,
      points: payload.state.points,
      scorePerPeriod: payload.state.scorePerPeriod,
      homeScore: payload.state.homeScore,
      awayScore: payload.state.awayScore,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
    },
  );

  return updatedEventDetails;
};

const formatPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodMinute = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodLabel = ({ period }) => PeriodLabels[period] ?? period;

const formatPeriodAbbreviation = ({ period }) => PeriodAbbreviations[period] ?? period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const formatTime = options?.formatTime ?? formatPeriodMinute;
  const separator = options?.separator ?? '·';

  const label = formatLabel(details);
  const time = formatTime(details);

  return (includes(activePeriods, details.period) ? `${label} ${separator} ${time}` : label);
};

const calculatePeriodProgress = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  if (!includes(activePeriods, period)) return -1;
  if (includes(PeriodAbbreviations[period], 'OT')) return (((overtimeLengthInMinutes * 60) - secondsLeftInPeriod) / (overtimeLengthInMinutes * 60)) * 100;
  return (((periodLengthInMinutes * 60) - secondsLeftInPeriod) / (periodLengthInMinutes * 60)) * 100;
};

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Full event',
  },
  ...map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods,
    }),
    (period) => ({
      id: period,
      label: PeriodLabels[period],
    }),
  ),
];

export default {
  ID,
  Periods,
  mustHavePeriods,
  mustHavePeriodsNcaa,
  activePeriods,
  PeriodAbbreviations,
  PeriodLabels,
  isPrematch,
  isLive,
  getPeriodTime,
  getPeriodLabel,
  getPeriodValue,
  getDetails,
  updateEvent,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  getAvailablePeriods,
  getLivePeriods,
};
