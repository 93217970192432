/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
import { intersection } from 'lodash';
import { getAllowedRoles } from '@/../config/userRoles';

export const getApplicationHubs = () => [
  {
    id: 'operations',
    label: 'Operations',
  },
  {
    id: 'product-admin',
    label: 'Product admin',
  },
];

export const getProductAdminLinks = () => [
  {
    key: 'market-templates',
    to: { name: 'market-templates' },
    label: 'Market Templates',
    allowedUserRoles: getAllowedRoles('templates'),
  },
  {
    key: 'mapping',
    label: 'Mapping',
    items: [
      {
        to: {
          name: 'eventsMapping',
        },
        label: 'Events mapping',
      },
      {
        to: {
          name: 'competitorsMapping',
        },
        label: 'Competitors mapping',
      },
      {
        to: {
          name: 'regionsMapping',
        },
        label: 'Regions mapping',
      },
      {
        to: {
          name: 'competitionsMapping',
        },
        label: 'Competitions mapping',
      },
      {
        to: {
          name: 'playersMapping',
        },
        label: 'Players mapping',
      },
    ],
    allowedUserRoles: getAllowedRoles('mapping'),
  },
  {
    key: 'configuration',
    label: 'Configuration',
    items: [
      {
        to: {
          name: 'market-display',
        },
        label: 'Market Display',
        allowedUserRoles: getAllowedRoles('marketConfig'),
      },
      {
        to: {
          name: 'selection-display',
        },
        label: 'Selection Display',
        allowedUserRoles: getAllowedRoles('selectionDisplay'),
      },
      {
        to: {
          name: 'market-groups',
        },
        label: 'Market Groups',
        allowedUserRoles: getAllowedRoles('marketConfig'),
      },
      {
        to: { name: 'competition-configuration' },
        label: 'Competition Configuration',
        allowedUserRoles: getAllowedRoles('competitionConfiguration'),
      },
      {
        to: {
          name: 'teamManualEdit',
        },
        label: 'Team Edit',
        allowedUserRoles: getAllowedRoles('manualEdit'),
      },
      {
        to: {
          name: 'playerManualEdit',
        },
        label: 'Player Edit',
        allowedUserRoles: getAllowedRoles('manualEdit'),
      },
    ],
  },
];

export const getOperationsLinks = () => [
  {
    key: 'events_book_events',
    to: { name: 'events_book_events' },
    label: 'Events',
    allowedUserRoles: getAllowedRoles('events'),
  },
  {
    key: 'bet-ticker',
    to: { name: 'bet-ticker' },
    label: 'Bet ticker',
    allowedUserRoles: getAllowedRoles('betTicker'),
  },
  {
    key: 'liability',
    to: { name: 'liability' },
    label: 'Liability',
    allowedUserRoles: getAllowedRoles('liability'),
  },
  {
    key: 'odds-checker',
    label: 'Odds Checker',
    allowedUserRoles: getAllowedRoles('oddsChecker'),
    items: [
      {
        to: {
          name: 'core-markets',
        },
        label: 'Core markets',
      },
      {
        to: {
          name: 'player-props',
        },
        label: 'Player props',
      },
    ],
  },
  {
    key: 'manual-resulting',
    to: { name: 'manual-resulting' },
    label: 'Manual Resulting',
    allowedUserRoles: getAllowedRoles('manualResulting'),
  },
];

export const getAllowedLinks = (userRoles, links) => {
  const allowedLinks = [];
  for (const link of links) {
    if (link.allowedUserRoles && !intersection(userRoles, link.allowedUserRoles).length) {
      continue;
    }

    if (!link.items) {
      allowedLinks.push(link);
      continue;
    }

    const allowedSublinks = getAllowedLinks(userRoles, link.items);
    if (!allowedSublinks.length) {
      continue;
    }

    allowedLinks.push({
      ...link,
      items: allowedSublinks,
    });
  }
  return allowedLinks;
};
