import {
  cloneDeep,
  find,
  isEqual,
  each,
  assign,
  isFunction,
  filter,
} from 'lodash';

const THROTTLE_TIME_IN_MS = 100;
const THROTTLE_UNTIL_COUNT = 100;

export const createMarketBuffer = (callback) => {
  if (!isFunction(callback)) throw new Error('Callback for market buffer must be a function');

  let buffer = null;
  let timer = 0;

  const tick = () => {
    const incomingMarkets = Object.values(buffer);
    if (!incomingMarkets.length) return;
    callback(incomingMarkets);
    buffer = {};
  };

  return {
    start() {
      if (timer) clearInterval(timer);
      buffer = {};
      timer = setInterval(tick, THROTTLE_TIME_IN_MS);
    },
    stop() {
      clearInterval(timer);
      buffer = null;
    },
    append(market) {
      if (!this.active) throw new Error('Call start() before appending to market buffer');
      buffer[market.marketId] = market;
      if (Object.keys(buffer).length >= THROTTLE_UNTIL_COUNT) tick();
    },
    get active() {
      return !!buffer;
    },
  };
};

export const mergeMarkets = (markets, incomingMarkets) => {
  let eventMarkets = cloneDeep(markets);

  each(incomingMarkets, (payload) => {
    let updateEventMarkets = false;
    const foundMarket = find(eventMarkets, { marketId: payload.marketId });

    if (!foundMarket) {
      if (payload.displayStatus !== 'ON') return;
      updateEventMarkets = true;

      const newMarket = {
        marketType: payload.marketType,
        marketCode: payload.marketType.marketCode,
        marketId: payload.marketId,
        selections: payload.selections,
        source: payload.source,
        status: payload.status,
        marketSummary: payload.marketSummary,
        displayStatus: payload.displayStatus,
        offeringStatus: payload.offeringStatus,
        inPlay: payload.inPlay,
      };
      eventMarkets.push(newMarket);
    } else {
      if (payload.displayStatus !== 'ON') {
        eventMarkets = filter(eventMarkets, (market) => market.marketId !== payload.marketId);
        return;
      }
      const changedMarketFields = {};
      if (foundMarket.displayStatus !== payload.displayStatus) {
        changedMarketFields.displayStatus = payload.displayStatus;
        updateEventMarkets = true;
      }
      if (foundMarket.offeringStatus !== payload.offeringStatus) {
        changedMarketFields.offeringStatus = payload.offeringStatus;
        updateEventMarkets = true;
      }
      if (foundMarket.inPlay !== payload.inPlay) {
        changedMarketFields.inPlay = payload.inPlay;
        updateEventMarkets = true;
      }
      if (!isEqual(foundMarket.marketSummary, payload.marketSummary)) {
        changedMarketFields.marketSummary = payload.marketSummary;
        updateEventMarkets = true;
      }

      if (updateEventMarkets) {
        assign(foundMarket, changedMarketFields);
      }
      each(foundMarket.selections, (selection) => {
        let updateEventMarketSelections = false;
        const foundSelection = find(
          payload.selections,
          (s) => (s.selectionType.selectionCode === selection.selectionType.selectionCode)
            && isEqual(s.selectionType.params, selection.selectionType.params),
        );
        if (!foundSelection) return;

        if (foundSelection.price.decimalValue > selection.price.decimalValue
          || foundSelection.price.decimalValue < selection.price.decimalValue
          || selection.displayStatus !== foundSelection.displayStatus
          || selection.offeringStatus !== foundSelection.offeringStatus) {
          updateEventMarketSelections = true;
        }

        if (updateEventMarketSelections) {
          let changedPrice = selection.price.changedPrice || null;
          if (foundSelection.price.decimalValue > selection.price.decimalValue) changedPrice = 'up';
          if (foundSelection.price.decimalValue < selection.price.decimalValue) changedPrice = 'down';

          const newPrice = { ...foundSelection.price, changedPrice };
          assign(selection, {
            price: newPrice,
            selectionType: foundSelection.selectionType,
            displayStatus: foundSelection.displayStatus,
            offeringStatus: foundSelection.offeringStatus,
          });
        }
      });
    }
  });

  return eventMarkets;
};

export const mergeManualResultingMarkets = (markets, incomingMarkets) => {
  const eventMarkets = cloneDeep(markets);

  each(incomingMarkets, (payload) => {
    const foundMarket = eventMarkets[payload.marketId];

    if (!foundMarket) {
      const newMarket = {
        marketType: payload.marketType,
        marketCode: payload.marketType.marketCode,
        marketId: payload.marketId,
        selections: payload.selections,
        source: payload.source,
        status: payload.status,
        marketSummary: payload.marketSummary,
        displayStatus: payload.displayStatus,
        offeringStatus: payload.offeringStatus,
        inPlay: payload.inPlay,
        limitConfiguration: payload.limitConfiguration,
      };
      eventMarkets[payload.marketId] = newMarket;
    } else {
      each(foundMarket.selections, (selection) => {
        const foundSelection = find(payload.selections, (s) => (s.selectionId === selection.selectionId));
        if (!foundSelection) return;

        if (foundSelection.resultingStatus !== selection.resultingStatus
          || foundSelection.outcome !== selection.outcome) {
          assign(selection, {
            ...selection,
            resultingStatus: foundSelection.resultingStatus,
            outcome: foundSelection.outcome,
          });
        }
      });
    }
  });

  return eventMarkets;
};

export const mergeTradingUiMarket = (markets, incomingMarket, multiViewList) => {
  const tradingMarkets = markets;

  let updateTradingMarkets = false;
  const foundMarket = multiViewList ? tradingMarkets[`${incomingMarket.eventId}_${incomingMarket.marketId}`] : tradingMarkets[incomingMarket.marketId];
  if (!foundMarket) {
    updateTradingMarkets = true;

    const newMarket = {
      marketType: incomingMarket.marketType,
      marketCode: incomingMarket.marketType.marketCode,
      marketId: incomingMarket.marketId,
      selections: incomingMarket.selections,
      source: incomingMarket.source,
      status: incomingMarket.status,
      marketSummary: incomingMarket.marketSummary,
      displayStatus: incomingMarket.displayStatus,
      offeringStatus: incomingMarket.offeringStatus,
      inPlay: incomingMarket.inPlay,
      limitConfiguration: incomingMarket.limitConfiguration,
      gateway: incomingMarket.gateway,
    };
    return {
      market: newMarket,
      update: true,
    };
  }
  const changedMarketFields = {};
  if (foundMarket.displayStatus !== incomingMarket.displayStatus) {
    changedMarketFields.displayStatus = incomingMarket.displayStatus;
    updateTradingMarkets = true;
  }
  if (foundMarket.offeringStatus !== incomingMarket.offeringStatus) {
    changedMarketFields.offeringStatus = incomingMarket.offeringStatus;
    updateTradingMarkets = true;
  }
  if (foundMarket.gateway !== incomingMarket.gateway) {
    changedMarketFields.gateway = incomingMarket.gateway;
    updateTradingMarkets = true;
  }
  if (!isEqual(foundMarket.marketSummary, incomingMarket.marketSummary)) {
    changedMarketFields.marketSummary = incomingMarket.marketSummary;
    updateTradingMarkets = true;
  }
  if (!isEqual(foundMarket.limitConfiguration, incomingMarket.limitConfiguration)) {
    changedMarketFields.limitConfiguration = incomingMarket.limitConfiguration;
    updateTradingMarkets = true;
  }
  if (foundMarket.inPlay !== incomingMarket.inPlay) {
    changedMarketFields.inPlay = incomingMarket.inPlay;
    updateTradingMarkets = true;
  }

  if (updateTradingMarkets) {
    assign(foundMarket, changedMarketFields);
  }
  each(foundMarket.selections, (selection) => {
    let updateTradingMarketSelections = false;
    const foundSelection = find(
      incomingMarket.selections,
      (s) => s.selectionId === selection.selectionId,
    );
    if (!foundSelection) return;

    if (foundSelection.price.decimalValue !== selection.price.decimalValue
      || selection.price.originalFormattedValue?.value !== foundSelection.price.originalFormattedValue?.value
      || selection.price.originalFormattedValue?.signIsPlus !== foundSelection.price.originalFormattedValue?.signIsPlus
      || selection.displayStatus !== foundSelection.displayStatus
      || selection.offeringStatus !== foundSelection.offeringStatus
      || selection.price.margin !== foundSelection.price.margin
      || selection.price.bias !== foundSelection.price.bias) {
      updateTradingMarketSelections = true;
    }

    if (updateTradingMarketSelections) {
      assign(selection, {
        price: foundSelection.price,
        selectionType: foundSelection.selectionType,
        displayStatus: foundSelection.displayStatus,
        offeringStatus: foundSelection.offeringStatus,
      });
    }
    if (updateTradingMarketSelections) updateTradingMarkets = true;
  });
  return {
    market: foundMarket,
    update: updateTradingMarkets,
  };
};

export const mergeOddsCheckerMarkets = (markets, incomingMarket) => {
  let eventMarkets = cloneDeep(markets);

  let updateEventMarkets = false;
  let updateEventMarketSelections = false;
  const foundMarket = find(eventMarkets, { marketId: incomingMarket.marketId });

  if (!foundMarket) {
    if (incomingMarket.displayStatus !== 'ON') {
      return {
        update: false,
      };
    }
    updateEventMarkets = true;

    const newMarket = {
      marketType: incomingMarket.marketType,
      marketCode: incomingMarket.marketType.marketCode,
      marketId: incomingMarket.marketId,
      eventId: incomingMarket.eventId,
      selections: incomingMarket.selections,
      source: incomingMarket.source,
      marketSummary: incomingMarket.marketSummary,
      offeringStatus: incomingMarket.offeringStatus,
      displayStatus: incomingMarket.displayStatus,
      inPlay: incomingMarket.inPlay,
    };
    eventMarkets.push(newMarket);
  } else {
    if (incomingMarket.displayStatus !== 'ON') {
      eventMarkets = filter(eventMarkets, (market) => market.marketId !== incomingMarket.marketId);
      return {
        update: true,
        markets: eventMarkets,
      };
    }
    const changedMarketFields = {};
    if (!isEqual(foundMarket.marketSummary, incomingMarket.marketSummary)) {
      changedMarketFields.marketSummary = incomingMarket.marketSummary;
      updateEventMarkets = true;
    }
    if (foundMarket.inPlay !== incomingMarket.inPlay) {
      changedMarketFields.inPlay = incomingMarket.inPlay;
      updateEventMarkets = true;
    }
    if (foundMarket.offeringStatus !== incomingMarket.offeringStatus) {
      changedMarketFields.offeringStatus = incomingMarket.offeringStatus;
      updateEventMarkets = true;
    }

    if (updateEventMarkets) {
      assign(foundMarket, changedMarketFields);
    }
    each(foundMarket.selections, (selection) => {
      let updateSelections = false;
      const foundSelection = find(
        incomingMarket.selections,
        (s) => (s.selectionType.selectionCode === selection.selectionType.selectionCode)
          && isEqual(s.selectionType.params, selection.selectionType.params),
      );
      if (!foundSelection) return;

      if (foundSelection.price.decimalValue !== selection.price.decimalValue) {
        updateSelections = true;
        updateEventMarketSelections = true;
      }

      if (updateSelections) {
        let changedPrice = selection.price.changedPrice || null;
        if (foundSelection.price.decimalValue > selection.price.decimalValue) changedPrice = 'up';
        if (foundSelection.price.decimalValue < selection.price.decimalValue) changedPrice = 'down';

        const newPrice = { ...foundSelection.price, changedPrice };
        assign(selection, {
          price: newPrice,
          selectionType: foundSelection.selectionType,
          displayStatus: foundSelection.displayStatus,
          offeringStatus: foundSelection.offeringStatus,
        });
      }
    });
  }

  return (updateEventMarkets || updateEventMarketSelections) ? { update: true, markets: eventMarkets } : { update: false };
};
