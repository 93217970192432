import { includes } from 'lodash';

const SUPER_ADMIN_CLIENT = 'Huddle';

const iconByOperator = {
  Huddle: 'huddle',
  Betr: 'betradar',
  // Mojo: 'betgenius',
  // Fanatics: 'fanatics',
};

export const getIconByOperator = (operator) => iconByOperator[operator];

export const getSuperAdminData = () => {
  // Checking if user is super admin
  const huddleRoles = localStorage.getItem('huddleRoles');
  const isSuperAdmin = includes(huddleRoles, 'SUPER_ADMIN');

  // Getting client info from query search
  const urlParams = new URLSearchParams(window.location.search);
  const client = urlParams.get('client') || SUPER_ADMIN_CLIENT;

  // Check if Huddle is selected
  const isSuperAdminSelected = isSuperAdmin && client === SUPER_ADMIN_CLIENT;

  return {
    isSuperAdmin,
    client,
    SUPER_ADMIN_CLIENT,
    isSuperAdminSelected,
  };
};

export const formatSuperAdminUrl = (url) => {
  const { isSuperAdminSelected } = getSuperAdminData();
  if (isSuperAdminSelected) {
    return `${url}/propagate`;
  }
  return url;
};
