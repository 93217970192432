import {
  includes, find, assign, each,
} from 'lodash';

const lineMarketCodes = ['GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];

const updateEventLiability = (event, payload) => ({
  event: {
    ...event,
    eventLiabilities: {
      nodes: [
        {
          betsCount: payload.betsCount,
          pnl: payload.pnl,
          volume: payload.volume,
        },
      ],
    },
  },
  update: true,
});

const updateMarketLiability = (event, payload) => {
  if (payload.marketType?.params?.SELECTIONS === 2 && includes(payload.marketCode, 'RESULT')) {
    const marketToUpdate = find(event.marketLiabilities.nodes, (market) => market.marketCode === payload.marketCode);
    if (!marketToUpdate) {
      event.marketLiabilities.nodes.push(payload);
    } else {
      each(event.marketLiabilities.nodes, (market) => {
        if (market.marketCode === payload.marketCode) {
          assign(market, { ...payload });
        }
      });
    }
    return {
      event,
      update: true,
    };
  }
  return {
    event,
    update: false,
  };
};

const updateAggregatedMarketLiability = (event, payload) => {
  if (payload.marketTypeWithoutLine?.params?.SELECTIONS === 2 && includes(lineMarketCodes, payload.marketCode)) {
    const marketToUpdate = find(event.lineMarketLiabilities.nodes, (market) => market.marketCode === payload.marketCode);
    if (!marketToUpdate) {
      event.lineMarketLiabilities.nodes.push(payload);
    } else {
      each(event.lineMarketLiabilities.nodes, (market) => {
        if (market.marketCode === payload.marketCode) {
          assign(market, { ...payload });
        }
      });
    }
    return {
      event,
      update: true,
    };
  }
  return {
    event,
    update: false,
  };
};

export const updateLiability = (event, payload) => {
  if (payload.messageType === 'MARKET_LIABILITY') {
    return updateMarketLiability(event, payload);
  }
  if (payload.messageType === 'AGGREGATED_MARKET_LIABILITY') {
    return updateAggregatedMarketLiability(event, payload);
  }
  return updateEventLiability(event, payload);
};
