import { ID as SOCCER_ID } from './soccer';
import { ID as BASKETBALL_ID } from './basketball';
import { ID as FOOTBALL_ID } from './football';
import { ID as ULTIMATE_ID } from './ultimate';

const BASEBALL_ID = '4e8eca10-6afa-44ed-af77-42414ec45cb3';
const RUGBY_LEAGUE_ID = '09315bc4-5ea0-4102-b467-c9558d30b1a1';
const RUGBY_UNION_ID = '3e74c8f1-e268-493f-9b3a-e45e52d24446';
const TENNIS_ID = '01330fb1-5b81-4b90-89fe-7f3796d8eb21';
const VOLLEYBALL_ID = '87740346-f7ec-480c-bb89-c494a3b683ea';
const HOCKEY_ID = 'db5e8b75-30a3-4a97-9112-f28b8e962887';

export default {
  SOCCER_ID,
  BASKETBALL_ID,
  FOOTBALL_ID,
  BASEBALL_ID,
  ULTIMATE_ID,
  RUGBY_LEAGUE_ID,
  RUGBY_UNION_ID,
  TENNIS_ID,
  VOLLEYBALL_ID,
  HOCKEY_ID,
};
