import {
  max,
  includes,
  clamp,
  assign,
  cloneDeep,
  map,
  keys,
  indexOf,
  reduce,
  filter,
  isArray,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import Event from './event';

export const ID = '09eb8090-68f3-4a53-838f-f79e7a6912c3';

const Periods = {
  N_A: 'N_A',
  PREMATCH: 'PREMATCH',
  IN_FIRST_HALF: 'IN_FIRST_HALF',
  AT_HALF_TIME: 'AT_HALF_TIME',
  IN_SECOND_HALF: 'IN_SECOND_HALF',
  NORMAL_TIME_COMPLETED: 'NORMAL_TIME_COMPLETED',
  IN_EXTRATIME_FIRST_HALF: 'IN_EXTRATIME_FIRST_HALF',
  AT_EXTRATIME_HALF_TIME: 'AT_EXTRATIME_HALF_TIME',
  IN_EXTRATIME_SECOND_HALF: 'IN_EXTRATIME_SECOND_HALF',
  EXTRA_TIME_COMPLETED: 'EXTRA_TIME_COMPLETED',
  IN_PENALTY_SHOOTOUTS: 'IN_PENALTY_SHOOTOUTS',
  EVENT_COMPLETED: 'EVENT_COMPLETED',
  SUSPENDED: 'SUSPENDED',
};

const mustHavePeriods = [
  Periods.IN_FIRST_HALF,
  Periods.IN_SECOND_HALF,
];

const activePeriods = [
  Periods.IN_FIRST_HALF,
  Periods.IN_SECOND_HALF,
  Periods.IN_EXTRATIME_FIRST_HALF,
  Periods.IN_EXTRATIME_SECOND_HALF,
];

const PeriodLabels = {
  TOTAL: 'Match',
  [Periods.N_A]: 'N/A',
  [Periods.IN_FIRST_HALF]: 'H1',
  [Periods.AT_HALF_TIME]: 'Half time',
  [Periods.IN_SECOND_HALF]: 'H2',
  [Periods.IN_EXTRATIME_FIRST_HALF]: 'ET1',
  [Periods.IN_EXTRATIME_SECOND_HALF]: 'ET2',
  [Periods.AT_EXTRATIME_HALF_TIME]: 'Break',
  [Periods.NORMAL_TIME_COMPLETED]: 'Break',
  [Periods.EVENT_COMPLETED]: 'Finished',
  [Periods.SUSPENDED]: 'Suspended',
};

const PeriodAbbreviations = {
  TOTAL: 'Match',
  [Periods.N_A]: 'N/A',
  [Periods.IN_FIRST_HALF]: 'H1',
  [Periods.AT_HALF_TIME]: 'Half time',
  [Periods.IN_SECOND_HALF]: 'H2',
  [Periods.IN_EXTRATIME_FIRST_HALF]: 'ET1',
  [Periods.IN_EXTRATIME_SECOND_HALF]: 'ET2',
  [Periods.AT_EXTRATIME_HALF_TIME]: 'Break',
  [Periods.NORMAL_TIME_COMPLETED]: 'Break',
  [Periods.EVENT_COMPLETED]: 'Finished',
  [Periods.SUSPENDED]: 'Suspended',
};

const livePeriods = [
  Periods.IN_FIRST_HALF,
  Periods.AT_HALF_TIME,
  Periods.IN_SECOND_HALF,
  Periods.NORMAL_TIME_COMPLETED,
  Periods.IN_EXTRATIME_FIRST_HALF,
  Periods.AT_EXTRATIME_HALF_TIME,
  Periods.IN_EXTRATIME_SECOND_HALF,
  Periods.EXTRA_TIME_COMPLETED,
  Periods.IN_PENALTY_SHOOTOUTS,
];

const getLivePeriods = () => [...livePeriods];

const isPrematch = (event) => Event.isPrematch(event) || includes(['N_A', 'PREMATCH'], event.period);

const isLive = (event) => Event.isLive(event) && event.period !== Periods.EVENT_COMPLETED;

const getPeriodTime = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const elapsedMinute = parseInt((elapsedSecondInPeriod || 0) / 60, 10) + 1;
  switch (period) {
  case 'IN_FIRST_HALF':
    return {
      currentMinute: clamp(elapsedMinute, 1, halfDuration),
      currentExtraMinute: max([0, elapsedMinute - halfDuration]),
    };
  case 'IN_SECOND_HALF':
    const secondHalfOffset = halfDuration;
    const secondHalfCurrentMinute = elapsedMinute + secondHalfOffset;

    return {
      currentMinute: clamp(secondHalfCurrentMinute, secondHalfOffset, secondHalfOffset + halfDuration),
      currentExtraMinute: max([0, secondHalfCurrentMinute - (secondHalfOffset + halfDuration)]),
    };
  case 'IN_EXTRATIME_FIRST_HALF':
    const firstHalfExtraOffset = halfDuration * 2;
    const firstHalfExtraCurrentMinute = elapsedMinute + firstHalfExtraOffset;

    return {
      currentMinute: clamp(firstHalfExtraCurrentMinute, firstHalfExtraOffset + extraTimeHalfDuration),
      currentExtraMinute: max([0, firstHalfExtraCurrentMinute - (firstHalfExtraOffset + extraTimeHalfDuration)]),
    };
  case 'IN_EXTRATIME_SECOND_HALF':
    const secondHalfExtraOffset = (halfDuration * 2) + extraTimeHalfDuration;
    const secondHalfExtraCurrentMinute = elapsedMinute + secondHalfExtraOffset;

    return {
      currentMinute: clamp(secondHalfExtraCurrentMinute, secondHalfExtraOffset, secondHalfExtraOffset + extraTimeHalfDuration),
      currentExtraMinute: max([0, secondHalfExtraCurrentMinute - (secondHalfExtraOffset + extraTimeHalfDuration)]),
    };
  default:
    return undefined;
  }
};

const getPeriodLabel = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const label = PeriodLabels[period] || '';
  if (!includes(activePeriods, period)) return label;

  const time = getPeriodTime({
    period,
    elapsedSecondInPeriod,
    halfDuration,
    extraTimeHalfDuration,
  });

  const labelBase = `${label} · ${time.currentMinute}`;
  const labelAddon = `+${time.currentExtraMinute}`;
  return `${labelBase}${time.currentExtraMinute ? labelAddon : ''}'`;
};

const getPeriodValue = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(keys(Periods), periodCode) + 1;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: [
        Periods.IN_FIRST_HALF,
        Periods.IN_SECOND_HALF,
      ],
    }),
    (period) => {
      const isVisible = !prematch && getPeriodValue(event.period) >= getPeriodValue(period);
      return {
        code: period,
        label: PeriodAbbreviations[period],
        homeValue: isVisible ? event.home.scorePerPeriod[period] || 0 : '',
        awayValue: isVisible ? event.away.scorePerPeriod[period] || 0 : '',
      };
    },
  );

  return [
    {
      code: 'TOTAL',
      label: PeriodAbbreviations.TOTAL,
      homeValue: prematch ? '-' : event.home.score,
      awayValue: prematch ? '-' : event.away.score,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      homeScore: payload.state.homeScore,
      homeScoreFirstHalf: payload.state.homeScoreFirstHalf,
      homeScoreSecondHalf: payload.state.homeScoreSecondHalf,
      homeYellowCards: payload.competitors.homeTeam.yellowCards,
      homeRedCards: payload.competitors.homeTeam.redCards,
      homeGoals: payload.competitors.homeTeam.goals,
      homeCorners: payload.competitors.homeTeam.corners,
      homeFouls: payload.competitors.homeTeam.fouls,
      homeShotsOnTarget: payload.competitors.homeTeam.shotsOnTarget,
      homeShotsOffTarget: payload.competitors.homeTeam.shotsOffTarget,
      homeSubstitutions: payload.competitors.homeTeam.substitutions,
      awayScore: payload.state.awayScore,
      awayScoreFirstHalf: payload.state.awayScoreFirstHalf,
      awayScoreSecondHalf: payload.state.awayScoreSecondHalf,
      awayYellowCards: payload.competitors.awayTeam.yellowCards,
      awayRedCards: payload.competitors.awayTeam.redCards,
      awayGoals: payload.competitors.awayTeam.goals,
      awayCorners: payload.competitors.awayTeam.corners,
      awayFouls: payload.competitors.awayTeam.fouls,
      awayShotsOnTarget: payload.competitors.awayTeam.shotsOnTarget,
      awayShotsOffTarget: payload.competitors.awayTeam.shotsOffTarget,
      awaySubstitutions: payload.competitors.awayTeam.substitutions,
      period: payload.state.period,
      elapsedSecondInPeriod: payload.state.elapsedSecondInPeriod,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
    },
  );

  return updatedEventDetails;
};

const formatPeriodTime = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const time = getPeriodTime({
    period,
    elapsedSecondInPeriod,
    halfDuration,
    extraTimeHalfDuration,
  });
  if (!time) return '';
  const secondsLeftInPeriod = (time.currentMinute + time.currentExtraMinute) * 60;
  return formatMinutesAndSeconds(secondsLeftInPeriod);
};

const formatPeriodMinute = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  const time = getPeriodTime({
    period,
    elapsedSecondInPeriod,
    halfDuration,
    extraTimeHalfDuration,
  });
  if (!time) return '';
  const labelBase = `${time.currentMinute}`;
  const labelAddon = `+${time.currentExtraMinute}`;
  return `${labelBase}${time.currentExtraMinute ? labelAddon : ''}'`;
};

const formatPeriodLabel = ({ period }) => PeriodLabels[period] ?? period;

const formatPeriodAbbreviation = ({ period }) => PeriodAbbreviations[period] ?? period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const formatTime = options?.formatTime ?? formatPeriodMinute;
  const separator = options?.separator ?? '·';

  const label = formatLabel(details);
  const time = formatTime(details);

  return (includes(activePeriods, details.period) ? `${label} ${separator} ${time}` : label);
};

const calculatePeriodProgress = ({
  period,
  elapsedSecondInPeriod,
  halfDuration,
  extraTimeHalfDuration,
}) => {
  if (!includes(activePeriods, period)) return -1;
  if (includes(PeriodAbbreviations[period], 'ET')) return (elapsedSecondInPeriod / (extraTimeHalfDuration * 60)) * 100;
  return (elapsedSecondInPeriod / (halfDuration * 60)) * 100;
};

const statisticTypes = {
  SHOTS: 'SHOTS',
  SHOTS_OFF_TARGET: 'SHOTS_OFF_TARGET',
  FOULS: 'FOULS',
  YELLOW_CARDS: 'YELLOW_CARDS',
  RED_CARDS: 'RED_CARDS',
};

const createStatisticsConfiguration = (event) => ({
  periods: Event.getAvailablePeriods({
    allPeriods: keys(Periods),
    scorePerPeriod: event.home.scorePerPeriod,
    mustHavePeriods: [
      Periods.IN_FIRST_HALF,
      Periods.IN_SECOND_HALF,
    ],
  }),
  fields: {
    [statisticTypes.SHOTS]: ['shotsOnTarget', 'shotsOffTarget'],
    [statisticTypes.SHOTS_OFF_TARGET]: 'shotsOffTarget',
    [statisticTypes.FOULS]: 'fouls',
    [statisticTypes.YELLOW_CARDS]: 'yellowCards',
    [statisticTypes.RED_CARDS]: 'redCards',
  },
  labels: {
    [statisticTypes.SHOTS]: 'Shots',
    [statisticTypes.SHOTS_OFF_TARGET]: 'Shots off target',
    [statisticTypes.FOULS]: 'Fouls',
    [statisticTypes.YELLOW_CARDS]: 'Yellow cards',
    [statisticTypes.RED_CARDS]: 'Red cards',
  },
  transform({
    period,
    field,
    data,
    value,
  }) {
    let entries = value;
    if (isArray(field)) entries = reduce(field, (combined, key) => combined.concat(data[key]), []);
    entries = filter(entries, { period });
    return entries.length;
  },
});

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Full event',
  },
  ...map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: [
        Periods.IN_FIRST_HALF,
        Periods.IN_SECOND_HALF,
      ],
    }),
    (period) => ({
      id: period,
      label: PeriodLabels[period],
    }),
  ),
];

export default {
  ID,
  Periods,
  mustHavePeriods,
  activePeriods,
  PeriodAbbreviations,
  PeriodLabels,
  isPrematch,
  isLive,
  getPeriodTime,
  getPeriodLabel,
  getDetails,
  updateEvent,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  createStatisticsConfiguration,
  getAvailablePeriods,
  getLivePeriods,
};
