/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { assign } from 'lodash';
import { getSuperAdminData } from './helpers/super-admin';
import * as Cognito from './api/cognito';

const client = axios.create({ timeout: 40000, timeoutErrorMessage: 'Request timed out' });

client.interceptors.request.use(
  async (config) => {
    const { token } = await Cognito.isUserLoggedIn();
    const { isSuperAdmin, client: selectedClient } = getSuperAdminData();
    assign(config, {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
    if (isSuperAdmin) {
      assign(config.headers, {
        'x-admin-for': selectedClient,
      });
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

client.interceptors.response.use((response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (!error.response) return Promise.reject(new Error('Forbidden access!'));
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { token } = await Cognito.isUserLoggedIn();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return client(originalRequest);
    }
    return Promise.reject(error);
  });

export default client;
